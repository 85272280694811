@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("../src/assets/fonts/Lato-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../src/assets/fonts/Lato-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Lato";
  src: url("../src/assets/fonts/Lato-Light.ttf");
  font-weight: lighter;
}


* {
  font-family: Montserrat;
  font-optical-sizing: auto;
}

body {
  font-family: Montserrat;
}